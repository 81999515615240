import React from 'react';
import {Navbar} from 'react-bootstrap';
import {AuthenticatedUser} from '../../authentication/Domain';
import OptionsDropdown from '../options/OptionsDropdown';
import {Link} from 'react-router-dom';

interface IProps {
    user: AuthenticatedUser;
    logoutFn: () => void;
}

const PrimaryMenu = (props: IProps) => {
    return <Navbar bg="primary" variant="dark">
        <Link to="/">
            <Navbar.Brand>
                    <span className="align-top ps-2">
                        <div className="logo-main d-none d-md-inline-block">&nbsp;</div>
                        <div className="logo-mobile d-inline-block d-md-none">&nbsp;</div>
                    </span>
                <span className="maintextcolor">&nbsp;WebEDI</span>
            </Navbar.Brand>
        </Link>
        <Navbar.Toggle/>
        <OptionsDropdown user={props.user} logoutFn={props.logoutFn}/>
    </Navbar>
}
export default PrimaryMenu;

import React, {Suspense, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {AuthenticatedUser} from '../authentication/Domain';
import PrimaryMenu from './primarymenu/PrimaryMenu';
import {useLocation, useSearchParams, Route, Routes, useNavigate} from 'react-router-dom';
import "./layout.css";
import i18n from '../i18n/i18n';
import NotFound from './notfound/NotFound';
import {
    ROLE_ACCOUNT_ADMINISTRATOR,
    ROLE_ORGANIZATION_ADMINISTRATOR
} from "../commons/CommonConstants";
import {addToast} from "../commons/components/toast/toasts";
import Loading from '../commons/Loading';
import {useTranslation} from 'react-i18next';
import {lazyLoadWithRetry} from "./Utils";
import Forecast from "../forecast/Forecast";
import { MergedVariablesContextProvider } from '../MergedVariablesContext';

interface Props {
    user: AuthenticatedUser;
    logoutFn: () => void;
}

const Home = lazyLoadWithRetry(() => import('../home/Home'));
const DocumentPage = lazyLoadWithRetry(() => import('../documents/DocumentPage'));
const ProductsPage = lazyLoadWithRetry(() => import('../product/ProductsPage'));
const CompanyPage = lazyLoadWithRetry(() => import('../company/CompanyPage'));
const AccountsPage = lazyLoadWithRetry(() => import('../account/AccountsPage'));
const TenantsPage = lazyLoadWithRetry(() => import('../tenant/TenantsPage'));
const TenantsDetailPage = lazyLoadWithRetry(() => import('../tenant/detail/TenantsDetailPage'));
const AccountDetailPage = lazyLoadWithRetry(() => import('../account/detail/AccountDetailPage'));
const TemplateGroupsPage = lazyLoadWithRetry(() => import('../templates/group/TemplateGroupsPage'));
const TemplateGroupDetailPage = lazyLoadWithRetry(() => import('../templates/group/TemplateGroupDetailPage'));
const FlowTemplatesPage = lazyLoadWithRetry(() => import('../templates/flow/FlowTemplatesPage'));
const FlowTemplateDetailPage = lazyLoadWithRetry(() => import('../templates/flow/FlowTemplateDetailPage'));
const DocumentTemplatesPage = lazyLoadWithRetry(() => import('../templates/document/DocumentTemplatesPage'));
const DocumentTemplateDetailPage = lazyLoadWithRetry(() => import('../templates/document/DocumentTemplateDetailPage'));
const AdvancedSettings = lazyLoadWithRetry(() => import('../advancedsettings/AdvancedSettings'));
const automotiveEnabled : boolean = !!((process.env.REACT_APP_AUTOMOTIVE_ENABLED ?? "").toLowerCase());

const Layout = (props: Props) => {
    useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = useSearchParams();
    const [primaryMenu, setPrimaryMenu] = useState(true);

    useEffect(() => {
            if (searchParams[0].has("_authrelogin") && searchParams[0].get("_authrelogin")?.length) {
                 const newLocation = location;
                newLocation.search = location.search.replace(new RegExp("._authrelogin=" + searchParams[0].get("_authrelogin")), "");
                navigate(newLocation);
                addToast(i18n.t("Notifications.ReLogin", {previousUser: searchParams[0].get("_authrelogin")}),
                    {appearance: 'warning', autoDismiss: true});
            }
        }

        , [searchParams, location, navigate])

    useEffect(() => {
        setPrimaryMenu(true);
    }, [location])

    const onHideTop = (hide: boolean): Promise<void> => {
        return new Promise(res => {
            setPrimaryMenu(!hide);
            res(undefined);
        });
    };

    const pages = <Routes>
        <Route path="/" element={
            <Suspense fallback={<Loading/>}>
                <Home user={props.user} onHideTop={onHideTop}/>
            </Suspense>
        }></Route>
        {automotiveEnabled ?
        <Route path="/forecast" element={
                <Forecast />
        }></Route> : null}
        <Route path="/documents/:flowid/:documentid" element={
            <Suspense fallback={<Loading/>}>
                <DocumentPage user={props.user} key={window.location.pathname}/>
            </Suspense>}>
        </Route>
        <Route path="/products/" element={
            <Suspense fallback={<Loading/>}><ProductsPage/></Suspense>
        }></Route>
        <Route path="/products/:productId" element={
            <Suspense fallback={<Loading/>}><ProductsPage/></Suspense>
        }></Route>
        <Route path="/company/" element={
            <Suspense fallback={<Loading/>}>
                <CompanyPage user={props.user}/>
            </Suspense>
        }></Route>
        {props.user.permissions >= ROLE_ORGANIZATION_ADMINISTRATOR ?
            [
                <Route path="/accounts/" key="route-accounts" element={
                    <Suspense fallback={<Loading/>}>
                        <AccountsPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/accounts/new" key="route-account" element={
                    <Suspense fallback={<Loading/>}>
                        <AccountDetailPage user={props.user} isNew={true}/>
                    </Suspense>
                }></Route>,
                <Route path="/accounts/:organizationname/:tenantid/:accountid" key="route-account" element={
                    <Suspense fallback={<Loading/>}>
                        <AccountDetailPage user={props.user} isNew={false}/>
                    </Suspense>
                }></Route>,
                <Route path="/template-groups/" key="route-template-groups" element={
                    <Suspense fallback={<Loading/>}>
                        <TemplateGroupsPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/template-groups/:organizationname/:templategroupid" key="route-template-group" element={
                    <Suspense fallback={<Loading/>}>
                        <TemplateGroupDetailPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/template-groups/new" key="route-template-group-new" element={
                    <Suspense fallback={<Loading/>}>
                        <TemplateGroupDetailPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/flow-templates/" key="route-flow-templates" element={
                    <Suspense fallback={<Loading/>}>
                        <FlowTemplatesPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/flow-templates/:flowtemplateid" key="route-flow-template" element={
                    <Suspense fallback={<Loading/>}>
                        <FlowTemplateDetailPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/document-templates/" key="route-document-templates" element={
                    <Suspense fallback={<Loading/>}>
                        <DocumentTemplatesPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/document-templates/:documenttemplateid" key="route-document-template" element={
                    <Suspense fallback={<Loading/>}>
                        <DocumentTemplateDetailPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/tenants/" key="route-tenants" element={
                    <Suspense fallback={<Loading/>}>
                        <TenantsPage user={props.user}/>
                    </Suspense>
                }></Route>,
                <Route path="/tenants/:tenantId" key="route-tenant" element={
                    <Suspense fallback={<Loading/>}>
                        <TenantsDetailPage user={props.user}/>
                    </Suspense>
                }></Route>
            ]
            : null
        }
        {props.user.permissions >= ROLE_ACCOUNT_ADMINISTRATOR ?
            [
                <Route path="/advanced-settings/" key="route-accounts" element={
                    <Suspense fallback={<Loading/>}>
                        <AdvancedSettings user={props.user}/>
                    </Suspense>
                }></Route>
            ]
            : null
        }
        <Route element={<NotFound/>}>
        </Route>
    </Routes>;

    const unauthorized = (<div className={"jumbotron"}>
        <h5>User '{props.user.authName}' is not authorized to access the application.</h5>
    </div>);

    return (<Container fluid={true} className="p-3 layout">
        {primaryMenu ? <PrimaryMenu user={props.user} logoutFn={props.logoutFn}/> : null}
        {props.user.authorized ? <MergedVariablesContextProvider>{(pages)}</MergedVariablesContextProvider> : (unauthorized)}
    </Container>);

}
export default Layout;


import React from 'react';
import {Trans} from 'react-i18next';
import {faCheck, faBolt, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {useNavigate} from "react-router";
import {INotification, NOTIFICATION_DELIVERY_FAILURE, NOTIFICATION_DOCUMENT_CREATED, NOTIFICATION_SENDING_ERROR, NOTIFICATION_SENDING_SUCCESSFUL} from "../../notifications/NotificationsDomain";
import i18n from '../../i18n/i18n';
import CommonUtils from '../../commons/CommonUtils';


interface INotificationItemInfo {
    icon: IconDefinition,
    message: string | JSX.Element
}

const getNotificationData = (notification: INotification): INotificationItemInfo => {
    switch (notification.type) {
        case NOTIFICATION_DOCUMENT_CREATED:
            return {
                icon: faEnvelope,
                message: <Trans i18nKey="Notifications.Tray.MessageReceived"
                                values={{translatedStepName: notification.data.stepName ? i18n.t(`Flows.Steps.${CommonUtils.removeBlanks(notification.data.stepName)}.Label`, {count: 1, defaultValue: notification.data.stepName}) : i18n.t("Notifications.Tray.GenericMessageType")}}></Trans>
            };
        case NOTIFICATION_SENDING_SUCCESSFUL:
            return {
                icon: faCheck,
                message: <Trans i18nKey="Notifications.Tray.MessageSent"></Trans>
            };
        case NOTIFICATION_DELIVERY_FAILURE:
            return {
                icon: faBolt,
                message: <Trans i18nKey="Notifications.Tray.MessageSentWithWarning"></Trans>
            };
        case NOTIFICATION_SENDING_ERROR:
            return {
                icon: faBolt,
                message: <Trans i18nKey="Notifications.Tray.MessageCouldNotBeSent"></Trans>
            };
        default:
            throw new Error("Unknown notification type")
    }
}

const displayTime = (date: Date) => {
    //re-create the date parameter with new Date(date) because JSON parsed date apparently doesn't know .getTime()
    const difference = new Date().getTime() - new Date(date).getTime();
    const minsAgo = Math.round(difference / 60000);
    const hoursAgo = Math.floor(minsAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const yearsAgo = Math.round(daysAgo / 365);
    switch (true) {
        case yearsAgo > 0 :
            return <Trans i18nKey="Notifications.Tray.YearsAgo" count={yearsAgo} values={{years: yearsAgo}}/>;
        case daysAgo > 0 :
            return <Trans i18nKey="Notifications.Tray.DaysAgo" count={daysAgo} values={{days: daysAgo}}/>;
        case hoursAgo > 0 :
            return <Trans i18nKey="Notifications.Tray.HoursAgo" count={hoursAgo} values={{hours: hoursAgo}}/>;
        default :
            return <Trans i18nKey="Notifications.Tray.MinsAgo" count={minsAgo} values={{mins: minsAgo}}/>;
    }
}

const NotificationItem = (notification: INotification) => {

    const navigate = useNavigate();

    const notificationData = getNotificationData(notification);
    const timeTranslation = notification.createdAt ? displayTime(notification.createdAt) : i18n.t("Notifications.Tray.DateMissing");

    return <div className={"notification-item notification-type-" + notification.type}
                onClick={() => navigate(`documents/${notification.data.flowId}/${notification.data.documentId}`)}>
        <div className={"notification-time"}>{timeTranslation}</div>
        <div className={"notification-item-header d-flex"}>
            <strong className={"pe-3"}><FontAwesomeIcon icon={notificationData.icon} size={"lg"}/> </strong>
            {notificationData.message}
        </div>
    </div>
}
export default NotificationItem;
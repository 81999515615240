import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AccountVariable } from './account/Domain';
import AccountService from './account/AccountService';

interface MergedVariablesContextType {
    variables: AccountVariable[];
    loadMergedVariables: () => void;
}

export const MergedVariablesContext = createContext<MergedVariablesContextType | undefined>(undefined);

interface MergedVariablesContextProviderProps {
    children: ReactNode;
}

export const MergedVariablesContextProvider: React.FC<MergedVariablesContextProviderProps> = ({ children }) => {
    const [variables, setVariables] = useState<AccountVariable[]>([]);

    const loadMergedVariables = async () => {
        try {
            const data = await AccountService.getInstance().retrieveMergedVariables();
            console.log("loaded merged variables", data);
            setVariables(data);
        } catch (error) {
            console.error("failed to load merged variables");
            setVariables([]);
        }
    };

    // init load the variables
    useEffect(() => {
        loadMergedVariables();
    }, []);

    return <MergedVariablesContext.Provider value={{ variables, loadMergedVariables }}> {children} </MergedVariablesContext.Provider>;
};
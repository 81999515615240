import React from 'react';
import i18n, { Language } from '../../i18n/i18n';
import {Col, NavDropdown, Row} from 'react-bootstrap';
import lodash from 'lodash';

type LanguageLabels = { [key: Language]: string; };
type AvailableLanguage = keyof LanguageLabels;

export default function LanguageMenu() {

    const defaultLang: string = "en";
    const languageLabels: LanguageLabels = {
        "en": "English",
        "bg": "Български",
        "de": "Deutsch",
        "cs": "Čeština",
        "hr": "Hrvatski",
        "hu": "Magyar",
        "bs": "Bosanski",
        "sr": "Српски",
        "pl": "Polski"
    };

    const activeLanguage = (): string => {
        return i18n.languages[0] || defaultLang
    }
    const supportedLanguageList = (): string[] => {
        return Object.keys(languageLabels)
    }
    const otherLanguageList = (): string[] => {
        return supportedLanguageList().filter((lang: string) => lang !== activeLanguage())
    }

    const getLabel = (lng: AvailableLanguage): JSX.Element => {
        return <Row>
            <Col xs={"3"}>{lng.toString().toUpperCase()}</Col>
            <Col xs={"9"} className={"text-secondary"}>{lodash.get(languageLabels, lng)}</Col>
        </Row>;
    }

    const changeLanguage = (lng: string) => {
        console.log("switching to " + lng);
        i18n.changeLanguage(lng);
    }

    const renderLanguage = (language: AvailableLanguage, short?: boolean): JSX.Element => {
        return short ?
            <span className={"active-language"}>{language.toString().toUpperCase()}</span> :
            getLabel(language)
    }

    return <NavDropdown className="flagdropdown navbar-brand " title={renderLanguage(activeLanguage(), true)} id="language-nav-dropdown" >
        {otherLanguageList().map((lang: string) => <NavDropdown.Item key={lang} onClick={() => { changeLanguage(lang) }}>
            {renderLanguage(lang)}
        </NavDropdown.Item>)}
    </NavDropdown>
}

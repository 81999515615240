import React, { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PUBSUB_SSE_EVENTS_TOPIC } from '../commons/CommonConstants';
import CommonUtils from '../commons/CommonUtils';
import { Flow, FlowDocument } from '../documents/DocumentDomain';
import DocumentService from '../documents/DocumentService';
import i18n from '../i18n/i18n';
import { NOTIFICATION_DELIVERY_FAILURE, NOTIFICATION_DOCUMENT_CREATED, NOTIFICATION_SENDING_ERROR, NOTIFICATION_SENDING_SUCCESSFUL, SystemNotification } from './NotificationsDomain';
import {addToast, removeToast} from "../commons/components/toast/toasts";
import PubSub from 'pubsub-js';

const Notifier = () => {
	const subscriptionToken : React.MutableRefObject<string | undefined> = useRef(undefined);

	const navigate = useNavigate();

	useEffect(() => {
		subscriptionToken.current = PubSub.subscribe(PUBSUB_SSE_EVENTS_TOPIC, (topic : string, notification : SystemNotification) => {
			console.log("Notifier got notification: '%o'", notification);
			if (notification.type === NOTIFICATION_DOCUMENT_CREATED) {
				DocumentService.getInstance().retrieveFlow(notification.data.flowId, false)
				.then(f => {
					const flowDocument : FlowDocument | undefined = f.getDocument(notification.data.documentId);
					if (flowDocument && flowDocument.creationType === "ARRIVAL") {
						notifyDocumentCreated(f, flowDocument);
					}
				});
			}
			if (notification.type === NOTIFICATION_SENDING_SUCCESSFUL) {
				notifySendingSuccessful();
			}
			if (notification.type === NOTIFICATION_DELIVERY_FAILURE) {
				notifyDeliveryFailed(notification.data);
			}
			if (notification.type === NOTIFICATION_SENDING_ERROR) {
				notifySendingError();
			}
		})
		return () => {
			if (subscriptionToken.current) {
				PubSub.unsubscribe(subscriptionToken.current);
				subscriptionToken.current = undefined;
			}
		}
	});

	const notifyDocumentCreated = (flow : Flow, flowDocument : FlowDocument) => {
		return DocumentService.getInstance().retrieveFlowTemplate(flow.flowTemplateId)
				.then(ft => [ft.steps[flowDocument.stepIndex].name, flowDocument.creationOption])
				.then(([stepName, creationOption]) => {
					let translatedStepName = i18n.t(`Flows.Steps.${CommonUtils.removeBlanks(stepName)}.Label`, {count: 1, defaultValue: stepName});
					const random = CommonUtils.randomString();
					const linkComp = <span id={random} style={{textDecoration: "underline", cursor: "pointer"}}
										onClick={(evt) => {
											navigate(`/documents/${flow._id}/${flowDocument.documentId}`);
											// remove the toast
											let id = evt.currentTarget.getAttribute("notification-id");
											id && removeToast(id);
										}}>{translatedStepName}</span>;
					let trans = <h5 style={{margin: "0.3rem 0.3rem 0.3rem 0.3rem"}}>
									{(creationOption === "UPDATE") ?
										<Trans i18nKey="Notifications.DocumentUpdated" values={{translatedStepName:translatedStepName}}>You received an updated {linkComp}.</Trans> :
										<Trans i18nKey="Notifications.DocumentReceived" values={{translatedStepName:translatedStepName}}>You received a new {linkComp}.</Trans>}
								</h5>
					addToast(trans, { appearance: 'info', autoDismiss: false, onOpen:  (id : any) => {
						// set the notification id to the link (to be able to close the notification on link click)
						document.getElementById(random)?.setAttribute("notification-id", id);
						// dismiss in 8 seconds
						setTimeout(() => removeToast(id), 8000);
					}});
				});
	}

	const notifySendingSuccessful = () => {
		addToast(i18n.t("Notifications.MessageSent", { appearance: 'success', autoDismiss: true }));
	}

	const notifyDeliveryFailed = (data : {sessionId: string, processingError: string, errorCode: string}) => {
		addToast(i18n.t("Notifications.MessageSentWithProcessingError", { session: data.sessionId, errorCode: data.errorCode, error: data.processingError }), { appearance: 'warning', autoDismiss: false });
	}

	const notifySendingError = () => {
		addToast(i18n.t("Notifications.MessageSendingError"), { appearance: 'error', autoDismiss: true });
	}

	return null;
};

export default Notifier;

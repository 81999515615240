import xhr from "../client/xhr";
import {INotification, NOTIFICATION_DELIVERY_FAILURE, NOTIFICATION_DOCUMENT_CREATED, NOTIFICATION_SENDING_ERROR, NOTIFICATION_SENDING_SUCCESSFUL} from "./NotificationsDomain";

class NotificationService {

    private static instance: NotificationService;

	public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    public getLastNotification() : Promise<INotification[]> {
       return xhr.get(`/gateway/notifications/notifications?types=${NOTIFICATION_DOCUMENT_CREATED},${NOTIFICATION_SENDING_SUCCESSFUL},${NOTIFICATION_DELIVERY_FAILURE},${NOTIFICATION_SENDING_ERROR}&creationType=ARRIVAL&_limit=10`).then((response : any) => response.data);
    }
}

const notificationService = NotificationService.getInstance();
export default notificationService;
import React, {useEffect, useRef, useState} from 'react';
import {AuthenticatedUser} from '../../authentication/Domain';
import {Trans} from 'react-i18next';
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationItem from "./NotificationItem";
import {INotification} from "../../notifications/NotificationsDomain";
import notificationService from "../../notifications/NotificationService";
import Loading from "../../commons/Loading";

interface IProps {
    user: AuthenticatedUser;
}

const ITEMS_PER_PAGE = 10;

const Notifications = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotification[] | undefined>();
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (containerRef.current && event.target && !containerRef.current.contains(event.target as Node)
                && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setShow(false);
            }
        }

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (show) {
            setLoading(true);
            notificationService.getLastNotification()
                .then(result => setNotifications(result.slice(0, ITEMS_PER_PAGE)))
                .finally(() => setLoading(false))
        }
    }, [show]);

    const toggleShow = () => {
        setShow(prevState => !prevState);
    }

    const notificationContainer = (
        <div className={"notification-container bg-white"} ref={containerRef}>
            {loading && !notifications?.length ?
                <div className="d-flex  m-2  justify-content-center  text-secondary"><Loading/></div> :
                (notifications?.length ? notifications.map(notification => <NotificationItem
                        key={"notification" + notification.id} {...notification} />) :
                    <div className="d-flex m-2 pt-1 justify-content-center text-secondary"><Trans
                        i18nKey={"Common.Table.NoDataMessage"}/>
                    </div>)
            }
        </div>
    );

    return props.user?.authorized ?
        <div className={"notification-toggle"} ref={buttonRef} onClick={toggleShow}>
            <FontAwesomeIcon className={"notification-button"} size={"xl"} icon={faBell}/>
            {show ? notificationContainer : null}
        </div> :
        null;
}

export default Notifications;
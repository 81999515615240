
export interface SystemNotification {
	type: string;
	accountId: string;
	data: any;
}

export interface INotification {
    id: string,
    type: string,
    createdAt?: Date;
    data: any
}

export const NOTIFICATION_DOCUMENT_CREATED = "document-created"
export const NOTIFICATION_SENDING_SUCCESSFUL = "sending-successful"
export const NOTIFICATION_DELIVERY_FAILURE = "delivery-failure"
export const NOTIFICATION_SENDING_ERROR = "sending-error"

